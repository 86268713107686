import React, { useState } from "react";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={8}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 8</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>You have probably gone into a drugstore or grocery store and have seen things like cold medicines or pain relievers that you can buy without a prescription. These are called over-the-counter (OTC) medications.</p>
            <p>Think back to the second commercial you watched earlier about Culturelle. You probably noticed that the commercial gave less information than the Stelara commercial. The second commercial is for a medication that does not require a prescription from a doctor.</p>
            <p>OTC medicines are safe when used as directed on the package. There are some differences between prescription and OTC medicines.</p>

            <UnorderedList>
              <li>OTC medicines need to follow rules set by the FDA. The rules include information about what is in the drug, the dosage, the safety information, and guidance for who should or should not use the drug.</li>
              <li>As long as an OTC drug meets these rules, the FDA does not review or approve the medication.</li>
              <li>If the OTC drug includes a new chemical or does not “fit” into the rules, then the company needs to work with the FDA to get the new OTC drug approved.</li>
            </UnorderedList>

          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>There are fewer regulations for selling OTC medications, with less involvement of the FDA. This is somewhat similar to how rules for an OTC product commercial are less strict than for prescription medication commercials. This does not mean that OTC medications are not regulated at all. An OTC medication has to follow guidelines set for the type of product.</p>
            <p>The FDA puts together a type of “recipe book” that gives the guidelines for different types of OTC medications that companies refer to for their product development. For example, a pain reliever that includes active ingredient X must contain an amount of ingredient X that is within a safe dosage range set for OTC pain relievers using that ingredient.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

